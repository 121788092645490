.auth-layout{
    height: 100vh;
    overflow-y: hidden;

    .left{
        background-color: $theme-black;
        width: 630px;
        height: 100vh;

        // .cartoon-img{
        //     width: 240px;
        //     object-fit: cover;
        //     object-position: center;
        // }

        h1{
            color: $theme-yellow;
            font-size: 32px;
        }

        p{
            color: $theme-white;
            font-size: 18px;
        }
    }

    .right{
        background-color: $theme-background;
        width: 100%;
        height: 100vh;
        overflow-y: scroll;

        .welcome-text{
            color: $theme-yellow;
        }

        .header{
            background-color: $theme-white;
            border-radius: 10px;

            h5{
                color: $theme-yellow;
            }

            h6{
                color: $theme-black;
            }
        }

        a{
            color: $theme-yellow;
            &:hover{
                text-decoration: underline;
            }
        }

        button{
            background-color: $theme-yellow;
            color: $theme-black;
            border-radius: 10px;
            transition: all 0.5s ease;
           
            &:hover,&:focus{
                background-color: transparent;
                border: 1px solid $theme-yellow;
                box-shadow: none;
            }
        }

        .btn-facebook{
            background-color: $theme-facebook;
            color: $theme-white;
            font-family: 'Open Sans';
            font-weight: 600;
            width: 140px;

            &:hover,&:focus{
                background-color: transparent;
                border: 1px solid  $theme-facebook;
                color: $theme-black;
                box-shadow: none;
            }
        }
        
        .btn-google{
            background-color: $theme-google;
            color: $theme-black;
            border: 1px solid $theme-gainsboro;
            font-family: 'Open Sans';
            font-weight: 600;
            width: 140px;

            &:hover,&:focus{
                background-color: transparent;
                border: 1px solid  $theme-gainsboro;
            }
        }

        input,
        .form-control {
            border:none;
            &:focus {
                box-shadow: 0px 0px 15px rgba(68, 130, 224, 0.1);
            }
        }

        .otp-input{
            input{
                height: 50px;
                width: 100% !important;
                padding: 0;
                margin: 0 5px;

                &:focus{
                    color: $theme-white;
                    background-color: $theme-yellow;
                }
            }
        }
    }
}
