.dashboard-layout {
    height: 100vh;
    overflow: hidden;

    &.remove-active-sidebar {
        .left {
            width: 70px;

            .navigation-controller {
                transform: rotate(180deg);
            }

            img {
                display: none;
            }

            small {
                display: none !important;
            }

            ul {
                li {
                    span {
                        display: none;
                    }
                }
            }
        }
    }

    .bg-background {
        background-color: $theme-background;
    }

    .left {
        border-radius: 20px;
        background-color: $theme-black;
        width: 300px;
        height: 93vh;
        position: relative;

        .overflow-controller {
            height: 100vh;
            padding-bottom: 200px;
            overflow: scroll;
        }

        .navigation-controller {
            cursor: pointer;
            background-color: $theme-white;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            color: $theme-yellow;
            font-size: 1.5rem;
            position: absolute;
            right: -22px;
            top: 22px;
        }

        small {
            color: $theme-yellow;
        }

        .col-no-padding {
            padding: 0;
        }

        ul {
            li {
                a,
                .nav-link {
                    height: 50px;
                    color: $theme-white;
                    @extend .Eurostile-bold;
                    transition: all 0.5s ease;
                    text-decoration: none;
                    border-bottom: none;
                    list-style: none;

                    &:hover,
                    &:focus {
                        text-decoration: none;
                        border-bottom: none;
                        list-style: none;
                        background-color: $theme-dashboard-link;
                        color: $theme-yellow;
                        svg {
                            path {
                                fill: $theme-yellow;
                            }
                            rect {
                                stroke: $theme-yellow;
                            }

                            &.setting {
                                path {
                                    stroke: $theme-yellow;
                                }
                            }
                            circle {
                                stroke: $theme-yellow;
                            }
                        }
                    }

                    &.active {
                        background-color: $theme-dashboard-link;
                        color: $theme-yellow;
                        svg {
                            path {
                                fill: $theme-yellow;
                            }
                            rect {
                                stroke: $theme-yellow;
                            }

                            &.setting {
                                path {
                                    stroke: $theme-yellow;
                                }
                            }
                            circle {
                                stroke: $theme-yellow;
                            }
                        }
                    }
                }

                svg {
                    path {
                        transition: all 0.5s ease;
                        fill: $theme-white;
                    }

                    circle {
                        transition: all 0.5s ease;
                    }

                    rect {
                        transition: all 0.5s ease;
                    }

                    &#no-fill-svg {
                        path {
                            transition: all 0.5s ease;
                            fill: none;
                        }
                    }
                }

                span {
                    font-size: 18px;
                }
            }
        }
    }

    .right {
        background-color: $theme-background;
        width: 100%;
        height: 100vh;
        overflow-y: scroll;

        .header {
            background-color: $theme-white;
            border-radius: 10px;

            h5 {
                color: $theme-yellow;

                em {
                    font-style: normal;
                    color: $theme-black;
                }
            }

            h6 {
                color: $theme-black;
            }

            .nav-link,
            a {
                color: $theme-black;
                @extend .Eurostile-bold;
            }

            .btn-create-post {
                border-radius: 5px;
                background-color: $theme-yellow;
                height: 40px;
                color: $theme-black;
                transition: all 0.5s ease;

                &:hover,
                &:focus {
                    background-color: transparent;
                    border: 1px solid $theme-yellow;
                }

                span {
                    font-size: 16px;
                }
            }

            .icon-container {
                position: relative;
                cursor: pointer;
                background-color: $theme-background;
                border-radius: 50%;
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;

                .icon-18{
                    font-size: 18px;
                }

                .text-theme-yellow{
                    color: $theme-yellow;
                }

                &.no-bg {
                    background-color: transparent;
                }

                .notification-icon{
                    &::after{
                        content: '';
                        content: '';
                        right: 13px;
                        width: 6px;
                        height: 6px;
                        border-radius: 50%;
                        background-color: #e09944;
                        position: absolute;
                        top: 14px;
                    }
                }
            }

            .avatar-container {
                cursor: pointer;

                img {
                    width: 40px;
                    height: 40px;
                }

                span {
                    font-size: 14px;
                    color: $theme-yellow;
                }

                small {
                    font-size: 12px;
                    margin-top: -2px;
                }
            }
        }
    }
}
