@font-face {
    font-family: 'Norwester';
    src: url('Norwester-Regular.eot');
    src: url('Norwester-Regular.eot?#iefix') format('embedded-opentype'),
        url('Norwester-Regular.woff2') format('woff2'),
        url('Norwester-Regular.woff') format('woff'),
        url('Norwester-Regular.ttf') format('truetype'),
        url('Norwester-Regular.svg#Norwester-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eurostile';
    src: url('Eurostile-Oblique.eot');
    src: url('Eurostile-Oblique.eot?#iefix') format('embedded-opentype'),
        url('Eurostile-Oblique.woff2') format('woff2'),
        url('Eurostile-Oblique.woff') format('woff'),
        url('Eurostile-Oblique.ttf') format('truetype'),
        url('Eurostile-Oblique.svg#Eurostile-Oblique') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Eurostile';
    src: url('EurostileBold.eot');
    src: url('EurostileBold.eot?#iefix') format('embedded-opentype'),
        url('EurostileBold.woff2') format('woff2'),
        url('EurostileBold.woff') format('woff'),
        url('EurostileBold.ttf') format('truetype'),
        url('EurostileBold.svg#EurostileBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eurostile Extended';
    src: url('EurostileExtended-Roman.eot');
    src: url('EurostileExtended-Roman.eot?#iefix') format('embedded-opentype'),
        url('EurostileExtended-Roman.woff2') format('woff2'),
        url('EurostileExtended-Roman.woff') format('woff'),
        url('EurostileExtended-Roman.ttf') format('truetype'),
        url('EurostileExtended-Roman.svg#EurostileExtended-Roman') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'EuroStyle';
    src: url('EuroStyleNormal.eot');
    src: url('EuroStyleNormal.eot?#iefix') format('embedded-opentype'),
        url('EuroStyleNormal.woff2') format('woff2'),
        url('EuroStyleNormal.woff') format('woff'),
        url('EuroStyleNormal.ttf') format('truetype'),
        url('EuroStyleNormal.svg#EuroStyleNormal') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

